// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { FC } from 'react';
import {
    ChipField,
    Datagrid,
    List,
    ResourceComponentProps,
    TextField,
    ImageField,
    ArrayField, SingleFieldList, DateFieldProps, FunctionField, useRecordContext,
} from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import { Icon } from '@iconify/react';
import moment from 'moment/moment';

const useStyles = makeStyles(() => createStyles({
    preview: {
        textAlign: 'center',
    },
}));

const CustomDateField: FC<DateFieldProps> = (props) => (
    <FunctionField render={(record: any) => moment(record[props.source!]).format('MMMM Do YYYY, HH:mm:ss')} />
);

const CustomImageField = (props) => {
    const { source, ...rest } = props;
    const record = useRecordContext(props);
    if (source in record) {
        return (
            <ImageField source={source} {...rest} />
        );
    }
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}
    ><Icon icon='ic:outline-broken-image' width='124' height='124' /></div>;
};

export const ProjectList: FC<ResourceComponentProps> = (props) => {
    const classes = useStyles();
    const rowClick = (id, resource, record) => `/projects/${id}?page=1`;
    return (
        <List {...props} sort={{ field: 'updated_date', order: 'DESC' }}>
            <>
                <Datagrid rowClick={rowClick}>
                    <TextField source='name' sortable={false} />
                    {/*
                        MATHIAS: Removed created by owner to hide ugly owner name
                        <TextField source='owner.username' sortable={false} label='owner' />
                    */}
                    <CustomDateField source='updated_date' label='last updated' />
                    <ArrayField source='labels' sortable={false}>
                        <SingleFieldList>
                            <ChipField source='name' />
                        </SingleFieldList>
                    </ArrayField>
                </Datagrid>
            </>
        </List>
    );
};
