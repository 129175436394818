// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

/**
 * Class representing a machine learning model
 * @memberof module:API.cvat.classes
 */
class MLModel {
    constructor(data) {
        this._id = data.id;
        this._name = data.name;
        this._kind = data.kind;
        this._framework = data.framework;
        this._description = data.description;
        this._project = data.project;
        this._type = data.type;

        this._params = {
            canvas: {
                minPosVertices: data.min_pos_points,
                minNegVertices: data.min_neg_points,
                startWithBox: data.startswith_box,
            },
        };
    }

    /**
     * @returns {string}
     * @readonly
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     * @readonly
     */
    get name() {
        return this._name;
    }

    /**
     * @returns {string[]}
     * @readonly
     */
    get labels() {
        return this._project.labels.map((e) => e.name);
    }

    /**
     * @returns {string}
     * @readonly
     */
    get framework() {
        return this._framework;
    }

    /**
     * @returns {string}
     * @readonly
     */
    get description() {
        return this._description;
    }

    get kind() {
        return this._kind;
    }

    get type() {
        return this._type;
    }

    /**
     * @returns {object}
     * @readonly
     */
    get params() {
        return {
            canvas: { ...this._params.canvas },
        };
    }
}

module.exports = MLModel;
