import * as React from 'react';
import { Link } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
import { Icon } from '@iconify/react';

const GitlabUrlField = ({ source }) => {
    const record = useRecordContext();
    const value = record && record[source];

    if (value == null) {
        return null;
    }

    return (
        <Link href={`https://gitlab.ml2grow.com/projects/${value}`}>
            <Icon icon='mdi:gitlab' color='primary' width='24' height='24' />
        </Link>
    );
};

GitlabUrlField.defaultProps = {
    addLabel: true,
};

export default GitlabUrlField;
