import { AuthProvider } from 'react-admin';
import jwt_decode from 'jwt-decode';
import User from './model/User';

const customAuthProvider = ({
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    user,
  }: any): AuthProvider => ({
    login: () => Promise.resolve(),
    logout: () => logout(),
    checkError: () => Promise.resolve(),
    checkAuth: () => (isAuthenticated ? Promise.resolve() : loginWithRedirect()),
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: async () => {
        const permissions = (jwt_decode(await getAccessTokenSilently()) as any).permissions;
        return Promise.resolve(new User(
            user.id,
            user.name,
            user.picture,
            permissions,
        ));
    },
  });

export default customAuthProvider;
