import React from "react";
import { useRecordContext } from "react-admin";
import get from 'lodash/get';
import { Box, Grid } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line';
import UnixTimestampDateField from '../UnixTimestampDateField';

const MetricChartField = ({ data }) => {
    const info = data || { id: 'test', data: [{ x: 0, y: 0 }] };
    const label = info.id;
    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box height={300} width={500}>
                    <ResponsiveLine
                        data={[info]}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        colors={{ scheme: 'category10' }}
                        enableGridX={false}
                        enableGridY={false}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'step',
                            legendOffset: 36,
                            legendPosition: 'middle',
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: label,
                            legendOffset: -40,
                            legendPosition: 'middle',
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
};

export const MetricGraphsField = (props) => {
    const record = useRecordContext(props);
    return (
        <>
            {get(record, props.source).map(item => (
                item.data === undefined ? <div /> : <MetricChartField key={item.data.id} data={item.data} />
            ))}
        </>
    );
};

MetricGraphsField.defaultProps = { label: 'Metrics', addLabel: true };

export default MetricGraphsField;
