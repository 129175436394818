// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import * as React from 'react';
import {
    ResourceComponentProps,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    TextField,
    Datagrid,
    ArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    TopToolbar,
    Button,
    useRedirect,
    useShowContext,
} from 'react-admin';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import { FC } from 'react';
import Image from 'react-image-enlarger';
import MetricGraphsField from './MetricGraphsField';
import UnixTimestampDateField from '../UnixTimestampDateField';
import ContentAdd from '@material-ui/icons/Add';
import { useUser } from '../../../model/User';

const Avatar = (props) => {
    const { source, title } = props;
    const record = useRecordContext(props);
    const titleValue = get(record, title) || title;
    const sourceValue = get(record, source);
    const [zoomed, setZoomed] = React.useState(false);

    return (
        <Image
            style={{ margin: '20px' }}
            zoomed={zoomed}
            src={sourceValue}
            alt={titleValue}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
        />
    );
};

const RegisterModelButton = () => {
    const { record } = useShowContext();
    const redirect = useRedirect();
    const { user } = useUser();
    if (!record || !user) return null; // user.canCreate('trainedmodel')
    const disabled = record.status !== 'FINISHED' || !user.canCreate('trainedmodel');
    const path = `/trainedmodels/create?source={"run":"${record.id}", "experiment": ${record.experiment}, "project": ${record.project.id}}`;
    return <Button color='primary' label='Register' onClick={() => redirect(path)} disabled={disabled}><ContentAdd /></Button>;
};

const RunShowActions = () => (
        <TopToolbar>
            <RegisterModelButton />
        </TopToolbar>
    );

export const RunShow: FC<ResourceComponentProps> = (props) => (
    <Show actions={<RunShowActions />} {...props}>
        <Grid container spacing={2}>
            <Grid container item xs={12} sm={12} md={3} lg={3} xl={3}>
                <SimpleShowLayout>
                    <TextField source='status' />
                    <TextField source='project.name' label='project' />
                    <TextField source='type' />
                    <UnixTimestampDateField source='start_time' />
                    <ArrayField source='labels'>
                        <SingleFieldList>
                            <ChipField source='name' />
                        </SingleFieldList>
                    </ArrayField>
                </SimpleShowLayout>
            </Grid>
            <Grid container item xs={12} sm={12} md={9} lg={9} xl={9}>
                <TabbedShowLayout >
                    <Tab label='Preview'>
                        <ArrayField source='previews'>
                            <SingleFieldList linkType={false}>
                                <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
                                    <Avatar source='url' title='description' />
                                </Grid>
                            </SingleFieldList>
                        </ArrayField>
                    </Tab>
                    <Tab label='Parameters'>
                        <ArrayField source='params'>
                            <Datagrid>
                                <TextField source='key' sortable={false} />
                                <TextField source='value' sortable={false} />
                            </Datagrid>
                        </ArrayField>
                    </Tab>
                    <Tab label='Metrics'>
                        <MetricGraphsField source='metrics' />
                    </Tab>
                </TabbedShowLayout>
            </Grid>
        </Grid>
    </Show>
);
