import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import logo from './img/logo.png';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 0
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: "64px",
    maxWidth: "120px",
    marginLeft: 0
  },
});

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar {...props} color='secondary' >
      <Toolbar>
        <img src={logo} alt="logo" className={classes.logo} />
      </Toolbar>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
      >Annotator</Typography>
    </AppBar >
  );
};

export default CustomAppBar;