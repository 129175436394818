// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

ReactDOM.render(
    <Auth0Provider
        domain='ml2ai.eu.auth0.com'
        clientId='LhtM5fRNUCyxJ2Q6poNVNMiX9YqsjkPV'
        audience='https://ai.ml2grow.com'
        redirectUri={window.location.origin}
    >
        <App />
    </Auth0Provider>,
    document.getElementById('root'),
);

/* window.addEventListener('error', (errorEvent: ErrorEvent) => {
    if (
        errorEvent.filename &&
        typeof errorEvent.lineno === 'number' &&
        typeof errorEvent.colno === 'number' &&
        errorEvent.error
    ) {
        const logPayload = {
            filename: errorEvent.filename,
            line: errorEvent.lineno,
            message: errorEvent.error.message,
            column: errorEvent.colno,
            stack: errorEvent.error.stack,
        };

        const store = getCVATStore();
        const state: CombinedState = store.getState();
        const { pathname } = window.location;
        const re = RegExp(/\/tasks\/[0-9]+\/jobs\/[0-9]+$/);
        const { instance: job } = state.annotation.job;
        if (re.test(pathname) && job) {
            job.logger.log(LogType.sendException, logPayload);
        } else {
            logger.log(LogType.sendException, logPayload);
        }
    }
}); */
