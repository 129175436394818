// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, {
    FC, useEffect, useState
} from 'react';
import {
    Create,
    SelectInput,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    ReferenceInput,
    FormDataConsumer,
    required,
} from 'react-admin';
import { useSelector } from 'react-redux';

export const TrainedModelsCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm redirect='list'>
                <TextInput source='name' validate={required()} />
                <TextInput source='description' multiline='true' validate={required()} />
                <ReferenceInput label='project' source='project' reference='ra-projects' validate={required()}>
                    <SelectInput optionText='name' optionValue='id' />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceInput key={formData.project} label='Experiment' source='experiment' reference='experiments' filter={formData.project ? { project: formData.project } : {}} {...rest}>
                            <SelectInput optionText='id' optionValue='id' />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const experiment = useSelector(state => state.admin.resources.experiments.data[formData.experiment]);
                        if (experiment) {
                            formData.container = experiment.modeltemplate.container;
                        }
                        return <TextInput label='container' source='container' validate={required()} />;
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceInput label='Run' source='run' reference='runs' filter={formData.experiment ? { experiment: formData.experiment } : {}} {...rest}>
                            <SelectInput optionText='id' optionValue='id' />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <ArrayInput source='arguments'>
                    <SimpleFormIterator>
                        <TextInput source='name' label='Name' />
                        <TextInput source='value' label='Value' />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    )
};
