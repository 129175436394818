import { map } from "lodash";
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
    AutocompleteArrayInput, Button,
    Create,
    FileField,
    FileFieldProps,
    SelectInputProps,
    SelectInput,
    SimpleForm,
    TextFieldProps,
} from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiBaseUrl } from '../../../data/customDataProvider';

export const ReportsCreate = (props: any) => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const [tasks, setTasks] = useState([]);
    console.log(tasks);

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            // eslint-disable-next-line react/destructuring-assignment
            const response = await fetch(`${getApiBaseUrl()}/tasks?page_size=10000`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const json = await response.json();
            setTasks(json.results);
        })();
    }, []);

    return (
        <Create {...props}>
            <SimpleForm redirect='list' mutationMode='pessimistic'>
                <SelectInput source='task' choices={tasks} optionText='name' optionValue='id' />
            </SimpleForm>
        </Create>);
};
