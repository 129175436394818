import { Identifier, useGetIdentity, UserIdentity } from 'react-admin';

function rtrim(x: string, characters: string) {
    let end = x.length - 1;
    while (characters.indexOf(x[end]) >= 0) {
        end -= 1;
    }
    return x.substr(0, end + 1);
}

export default class User implements UserIdentity {
    id: Identifier;
    fullName: string;
    avatar: string;
    permissions: string[];

    public constructor(id: Identifier, fullName: string, avatar: string, permissions: string[]) {
        this.id = id;
        this.fullName = fullName;
        this.avatar = avatar;
        this.permissions = permissions;
    }

    canView(resource: string): boolean {
        const perm = rtrim(resource, 's');
        return this.permissions.includes(`${perm}:list`);
    }

    canCreate(resource: string): boolean {
        const perm = rtrim(resource, 's');
        return this.permissions.includes(`${perm}:create`);
    }
}

export const useUser = () => {
    const { identity, loading } = useGetIdentity();
    return { user: identity as User, loading: loading };
};
