// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import {
    ChipField, Datagrid, List, ResourceComponentProps, TextField, TextFieldProps, CreateButton, Toolbar, Button, DateField, DateFieldProps, FunctionField,
} from 'react-admin';
import Pending from '@material-ui/icons/HourglassEmpty';
import Published from '@material-ui/icons/SpellcheckOutlined';
import Draft from '@material-ui/icons/DraftsOutlined';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiBaseUrl } from '../../../data/customDataProvider';
import { useUser } from '../../../model/User';

const StateField: FC<TextFieldProps> = (props) => {
    switch (props.record!.status) {
        case 'pending': return <ChipField icon={<Published />} source='status' color='default' />;
        case 'created': return <ChipField icon={<Pending />} source='status' color='primary' />;
        default: return <ChipField source='status' color='secondary' />;
    }
};

const ReportsListToolbar = (props: any) => (
    <Toolbar {...props}>
        <CreateButton />
    </Toolbar>
);

const ExportButtonField: FC<TextFieldProps> = (props) => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const handleExport = useCallback(
        (event: any) => {
            (async () => {
                const token = await getAccessTokenSilently();
                // eslint-disable-next-line react/destructuring-assignment
                const result = await fetch(`${getApiBaseUrl()}/reports/${props.record!.id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const blob = await result.blob();
                const href = window.URL.createObjectURL(blob);
                window.open(href);
            })();
            event.stopPropagation();
        }, [],
    );
    if (props.record!.status === 'created') {
        return (
            <Button label='Export' color='primary' onClick={handleExport}>
                <PictureAsPdf />
            </Button>
        );
    }
    return <div />;
};

const CustomDateField: FC<DateFieldProps> = (props) => (
    <FunctionField render={(record: any) => moment(record[props.source!]).format('MMMM Do YYYY, HH:mm:ss')} />
);

export const ReportsList: FC<ResourceComponentProps> = (props) => {
    const { user } = useUser();
    if (!user) {
        return null;
    }

    return (
        <List {...props} sort={{ field: 'created_date', order: 'DESC' }} hasCreate={user.canCreate('report')}>
            <>
                <Datagrid>
                    <TextField source='task.name' sortable={false} label='Task' />
                    <StateField source='status' />
                    <CustomDateField source='created_date' />
                    <ExportButtonField source='id' sortable={false} label='Export' />
                </Datagrid>
            </>
        </List>
    );
};
