// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col, Row } from 'antd/lib/grid';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import { connect } from 'react-redux';

import getCore from 'cvat-core-wrapper';
import { getOrganizationsAsync } from 'actions/organization-actions';
import ExportDatasetModal from 'components/export-dataset/export-dataset-modal';
import Spin from 'antd/lib/spin';
import { getAboutAsync } from '../actions/about-actions';
import { authorizedAsync, loadAuthActionsAsync } from '../actions/auth-actions';
import { getFormatsAsync } from '../actions/formats-actions';
import { getModelsAsync } from '../actions/models-actions';
import { getPluginsAsync } from '../actions/plugins-actions';
import { getProjectsAsync } from '../actions/projects-actions';

import { switchSettingsDialog } from '../actions/settings-actions';
import { shortcutsActions } from '../actions/shortcuts-actions';
import { getUserAgreementsAsync } from '../actions/useragreements-actions';
import GlobalHotKeys, { KeyMap } from '../utils/mousetrap-react';
import { resetErrors, resetMessages } from '../actions/notification-actions';
import { CombinedState, NotificationsState } from '../reducers/interfaces';

import showPlatformNotification, { platformInfo, stopNotifications, showUnsupportedNotification } from '../utils/platform-checker';

interface StateToProps {
    pluginsInitialized: boolean;
    pluginsFetching: boolean;
    modelsInitialized: boolean;
    modelsFetching: boolean;
    userInitialized: boolean;
    userFetching: boolean;
    organizationsFetching: boolean;
    organizationsInitialized: boolean;
    aboutInitialized: boolean;
    aboutFetching: boolean;
    formatsInitialized: boolean;
    formatsFetching: boolean;
    userAgreementsInitialized: boolean;
    userAgreementsFetching: boolean;
    authActionsFetching: boolean;
    authActionsInitialized: boolean;
    allowChangePassword: boolean;
    allowResetPassword: boolean;
    notifications: NotificationsState;
    user: any;
    keyMap: KeyMap;
    isModelPluginActive: boolean;
}

interface DispatchToProps {
    loadFormats: () => void;
    verifyAuthorized: (callback: () => Promise<any>) => void;
    loadAbout: () => void;
    initModels: () => void;
    initPlugins: () => void;
    initProjects: () => void;
    resetErrors: () => void;
    resetMessages: () => void;
    switchShortcutsDialog: () => void;
    loadUserAgreements: () => void;
    switchSettingsDialog: () => void;
    loadAuthActions: () => void;
    loadOrganizations: () => void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const { plugins } = state;
    const { projects } = state;
    const { auth } = state;
    const { formats } = state;
    const { about } = state;
    const { shortcuts } = state;
    const { userAgreements } = state;
    const { models } = state;
    const { organizations } = state;

    return {
        userInitialized: auth.initialized,
        userFetching: auth.fetching,
        organizationsFetching: organizations.fetching,
        organizationsInitialized: organizations.initialized,
        pluginsInitialized: plugins.initialized,
        pluginsFetching: plugins.fetching,
        projectsInitialized: projects.initialized,
        projectsFetching: projects.fetching,
        modelsInitialized: models.initialized,
        modelsFetching: models.fetching,
        aboutInitialized: about.initialized,
        aboutFetching: about.fetching,
        formatsInitialized: formats.initialized,
        formatsFetching: formats.fetching,
        userAgreementsInitialized: userAgreements.initialized,
        userAgreementsFetching: userAgreements.fetching,
        authActionsFetching: auth.authActionsFetching,
        authActionsInitialized: auth.authActionsInitialized,
        allowChangePassword: auth.allowChangePassword,
        allowResetPassword: auth.allowResetPassword,
        notifications: state.notifications,
        user: auth.user,
        keyMap: shortcuts.keyMap,
        isModelPluginActive: plugins.list.MODELS,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        loadFormats: (): void => dispatch(getFormatsAsync()),
        verifyAuthorized: (getToken: () => Promise<any>): void => dispatch(authorizedAsync(getToken)),
        loadUserAgreements: (): void => dispatch(getUserAgreementsAsync()),
        initPlugins: (): void => dispatch(getPluginsAsync()),
        initModels: (): void => dispatch(getModelsAsync()),
        initProjects: (): void => dispatch(getProjectsAsync({})),
        loadAbout: (): void => dispatch(getAboutAsync()),
        resetErrors: (): void => dispatch(resetErrors()),
        resetMessages: (): void => dispatch(resetMessages()),
        switchShortcutsDialog: (): void => dispatch(shortcutsActions.switchShortcutsDialog()),
        switchSettingsDialog: (): void => dispatch(switchSettingsDialog()),
        loadAuthActions: (): void => dispatch(loadAuthActionsAsync()),
        loadOrganizations: (): void => dispatch(getOrganizationsAsync()),
    };
}

interface CVATAppProps {
    loadFormats: () => void;
    loadAbout: () => void;
    verifyAuthorized: (callback: () => Promise<any>) => void;
    loadUserAgreements: () => void;
    initPlugins: () => void;
    initModels: () => void;
    initProjects: () => void;
    resetErrors: () => void;
    resetMessages: () => void;
    switchShortcutsDialog: () => void;
    switchSettingsDialog: () => void;
    loadAuthActions: () => void;
    loadOrganizations: () => void;
    getToken: (callback: () => Promise<any>) => void;
    keyMap: KeyMap;
    userInitialized: boolean;
    userFetching: boolean;
    organizationsFetching: boolean;
    organizationsInitialized: boolean;
    pluginsInitialized: boolean;
    pluginsFetching: boolean;
    projectsInitialized: boolean;
    projectsFetching: boolean;
    modelsInitialized: boolean;
    modelsFetching: boolean;
    formatsInitialized: boolean;
    formatsFetching: boolean;
    aboutInitialized: boolean;
    aboutFetching: boolean;
    userAgreementsFetching: boolean;
    userAgreementsInitialized: boolean;
    authActionsFetching: boolean;
    authActionsInitialized: boolean;
    notifications: NotificationsState;
    user: any;
    isModelPluginActive: boolean;
}

class CVATApplication extends React.PureComponent<CVATAppProps> {
    public componentDidMount(): void {
        const core = getCore();
        const { verifyAuthorized, getToken } = this.props;
        // configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

        // Logger configuration
        const userActivityCallback: (() => void)[] = [];
        window.addEventListener('click', () => {
            userActivityCallback.forEach((handler) => handler());
        });
        core.logger.configure(() => window.document.hasFocus, userActivityCallback);

        verifyAuthorized(getToken);

        const {
            name, version, engine, os,
        } = platformInfo();

        if (showPlatformNotification()) {
            stopNotifications(false);
            Modal.warning({
                title: 'Unsupported platform detected',
                className: 'cvat-modal-unsupported-platform-warning',
                content: (
                    <>
                        <Row>
                            <Col>
                                <Text>
                                    {`The browser you are using is ${name} ${version} based on ${engine}.` +
                                        ' CVAT was tested in the latest versions of Chrome and Firefox.' +
                                        ' We recommend to use Chrome (or another Chromium based browser)'}
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text type='secondary'>{`The operating system is ${os}`}</Text>
                            </Col>
                        </Row>
                    </>
                ),
                onOk: () => stopNotifications(true),
            });
        } else if (showUnsupportedNotification()) {
            stopNotifications(false);
            Modal.warning({
                title: 'Unsupported features detected',
                className: 'cvat-modal-unsupported-features-warning',
                content: (
                    <Text>
                        {`${name} v${version} does not support API, which is used by CVAT. `}
                        It is strongly recommended to update your browser.
                    </Text>
                ),
                onOk: () => stopNotifications(true),
            });
        }
    }

    public componentDidUpdate(): void {
        const {
            verifyAuthorized,
            loadFormats,
            loadAbout,
            initPlugins,
            initModels,
            initProjects,
            userInitialized,
            userFetching,
            formatsInitialized,
            formatsFetching,
            aboutInitialized,
            aboutFetching,
            pluginsInitialized,
            pluginsFetching,
            projectsInitialized,
            projectsFetching,
            modelsInitialized,
            modelsFetching,
            user,
            isModelPluginActive,
            getToken,
        } = this.props;

        this.showErrors();
        this.showMessages();

        if (!userInitialized && !userFetching) {
            verifyAuthorized(getToken);
            return;
        }

        if (user == null || !user.isVerified) {
            return;
        }

        if (!formatsInitialized && !formatsFetching) {
            loadFormats();
        }

        if (!aboutInitialized && !aboutFetching) {
            loadAbout();
        }

        if (isModelPluginActive && !modelsInitialized && !modelsFetching) {
            initModels();
        }

        if (!pluginsInitialized && !pluginsFetching) {
            initPlugins();
        }

        if (!projectsInitialized && !projectsFetching) {
            initProjects();
        }
    }

    private showMessages(): void {
        function showMessage(title: string): void {
            notification.info({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { notifications, resetMessages } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.messages)) {
            for (const what of Object.keys((notifications as any).messages[where])) {
                const message = (notifications as any).messages[where][what];
                shown = shown || !!message;
                if (message) {
                    showMessage(message);
                }
            }
        }

        if (shown) {
            resetMessages();
        }
    }

    private showErrors(): void {
        function showError(title: string, _error: any, className?: string): void {
            const error = _error.toString();
            const dynamicProps = typeof className === 'undefined' ? {} : { className };
            notification.error({
                ...dynamicProps,
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
                description: error.length > 200 ? 'Open the Browser Console to get details' : error,
            });

            // eslint-disable-next-line no-console
            console.error(error);
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { notifications, resetErrors } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.errors)) {
            for (const what of Object.keys((notifications as any).errors[where])) {
                const error = (notifications as any).errors[where][what];
                shown = shown || !!error;
                if (error) {
                    showError(error.message, error.reason, error.className);
                }
            }
        }

        if (shown) {
            resetErrors();
        }
    }

    // Where you go depends on your URL
    public render(): JSX.Element {
        const {
            keyMap,
            switchShortcutsDialog,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            switchSettingsDialog,
            userInitialized,
            formatsInitialized,
            pluginsInitialized,
            aboutInitialized,
            isModelPluginActive,
            modelsInitialized,
            user,
        } = this.props;
        const subKeyMap = {
            SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS,
            SWITCH_SETTINGS: keyMap.SWITCH_SETTINGS,
        };

        const handlers = {
            SWITCH_SHORTCUTS: (event: KeyboardEvent) => {
                if (event) event.preventDefault();
                switchShortcutsDialog();
            },
            SWITCH_SETTINGS: (event: KeyboardEvent) => {
                if (event) event.preventDefault();

                switchSettingsDialog();
            },
        };

        const readyForRender =
            (userInitialized && (user == null || !user.isVerified)) ||
            (userInitialized &&
                formatsInitialized &&
                pluginsInitialized &&
                aboutInitialized &&
                (!isModelPluginActive || modelsInitialized));

        if (readyForRender) {
            return (
                <div>
                    <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}>
                        <div />
                    </GlobalHotKeys>
                    <ExportDatasetModal />
                    {/* eslint-disable-next-line */}
                    <a id='downloadAnchor' target='_blank' style={{ display: 'none' }} download />
                </div>
            );
        }

        return <Spin size='large' className='cvat-spinner' />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CVATApplication);
//                <ExportDatasetModal />
