// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, {
    FC, useEffect, useState,
} from 'react';
import {
    Create,
    SelectInput,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    NumberInput,
    required,
    RadioButtonGroupInput,
} from 'react-admin';

export const ModelTemplatesCreate = (props: any) => {
    const metricTypes = [
        { id: 'minimize', name: 'Minimize' },
        { id: 'maximize', name: 'Maximize' },
    ];
    const templateTypes = [
        { name: 'Object Detection' },
        { name: 'Image Classification' },
        { name: 'Image Segmentation' },
    ];
    const machineTypes = [
        { name: 'standard-4' },
        { name: 'standard-8' },
        { name: 'standard-12' },
        { name: 'highmem-4' },
        { name: 'highmem-8' },
        { name: 'highmem-12' },
        { name: 'ultrahighmem-4' },
        { name: 'ultrahighmem-8' },
        { name: 'ultrahighmem-12' },
    ];

    const acceleratorTypes = [
        { name: "nvidia-tesla-k80" },
        { name: "nvidia-tesla-t4" },
        { name: "nvidia-tesla-a100" },
    ]

    return (
        <Create {...props}>
            <SimpleForm redirect='list'>
                <TextInput source='name' validate={required()} />
                <TextInput source='description' multiline='true' />
                <NumberInput source='repo' validate={required()} />
                <TextInput source='container' validate={required()} />
                <SelectInput source='type' choices={templateTypes} optionText='name' optionValue='name' validate={required()} />
                <ArrayInput source='override'>
                    <SimpleFormIterator>
                        <TextInput source='name' label='Name' />
                        <TextInput source='value' label='Value' />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source='metrics'>
                    <SimpleFormIterator>
                        <TextInput source='name' label='Name' />
                        <RadioButtonGroupInput source='type' choices={metricTypes} label='type' />
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectInput source='machine_type' choices={machineTypes} optionText='name' optionValue='name' validate={required()} />
                <SelectInput source='type' choices={acceleratorTypes} optionText='name' optionValue='name' validate={required()} />
            </SimpleForm>
        </Create>
    );
};
