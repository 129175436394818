// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import * as React from 'react';
import {
    ResourceComponentProps,
    Show,
    SimpleShowLayout,
    TextField,
    Datagrid,
    ArrayField,
    SingleFieldList,
    BooleanField,
    ChipField, DateFieldProps, FunctionField,
} from 'react-admin';
import { FC } from 'react';
import UnixTimestampDateField from '../UnixTimestampDateField';
import RunStateField from '../RunStateField';
import moment from 'moment';

const CustomDateField: FC<DateFieldProps> = (props) => (
    <FunctionField label={props.label} render={(record: any) => moment(record[props.source!]).format('MMMM Do YYYY, HH:mm:ss')} />
);

export const ExperimentShow: FC<ResourceComponentProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source='id' sortable={false} />
            <CustomDateField source='created_date' label='Created' />
            <TextField source='project.name' sortable={false} label='project' />
            <TextField source='modeltemplate.name' sortable={false} label='model template' />
            <TextField source='description' sortable={false} label='description' />
            <ArrayField source='parameters'>
                <Datagrid rowClick={undefined}>
                    <TextField source='name' sortable={false} />
                    <BooleanField source='optimize' />
                    <TextField source='value' sortable={false} />
                    <TextField source='minimum' sortable={false} />
                    <TextField source='maximum' sortable={false} />
                    <ArrayField source='choices' sortable={false}>
                        <SingleFieldList>
                            <ChipField source='value' />
                        </SingleFieldList>
                    </ArrayField>
                </Datagrid>
            </ArrayField>
            <ArrayField source='runs'>
                <Datagrid rowClick={(id, resource, record) => `/runs/${record.id}/show`}>
                    <RunStateField source='status' sortable={false} label='Id' />
                    <TextField source='type' sortable={false} />
                    <ArrayField source='labels' sortable={false}>
                        <SingleFieldList>
                            <ChipField source='name' />
                        </SingleFieldList>
                    </ArrayField>
                    <ArrayField source='metrics' sortable={false}>
                        <SingleFieldList>
                            <ChipField source='name' />
                        </SingleFieldList>
                    </ArrayField>
                    <UnixTimestampDateField source='start_time' sortable={false} />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>

    </Show>
);
