import { defaultTheme } from "react-admin";
import { createTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";

export const theme = createTheme(
    merge({}, defaultTheme, {
        palette: {
            primary: {
                main: '#00BAE5',
            },
            failed: {
                main: '#C1666B',
            },
        },
    })
  );
