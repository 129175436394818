// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import thunk from 'redux-thunk';
import { createStore, applyMiddleware, Store, Reducer } from 'redux';
import { createLogger } from 'redux-logger';
import { isDev } from 'utils/enviroment';

let store: Store | null = null;

export function setCVATStore(ns: Store) {
    store = ns;
}

export function getCVATStore(): Store {
    if (store) {
        return store;
    }

    throw new Error('First create a store');
}
