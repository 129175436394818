// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { FC } from 'react';
import {
    ChipField, Datagrid, List, ResourceComponentProps, TextField,
} from 'react-admin';
import GitlabUrlField from '../GitlabUrlField';
import { useUser } from '../../../model/User';

export const ModelTemplatesList: FC<ResourceComponentProps> = (props) => {
    const { user } = useUser();
    if (!user) {
        return null;
    }
    return (
        <List {...props} sort={{ field: 'start_time', order: 'DESC' }} hasCreate={user.canCreate('modeltemplates')}>
            <>
                <Datagrid>
                    <TextField source='name' sortable={false} />
                    <TextField source='description' sortable={false} />
                    <TextField source='container' sortable={false} />
                    <GitlabUrlField source='repo' />
                    <ChipField source='type' />
                </Datagrid>
            </>
        </List>
    );
};
