// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { FC } from 'react';
import {
    ChipField,
    Datagrid,
    List,
    ResourceComponentProps,
    TextField,
    FilterList,
    FilterListItem,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import { Box, Card, CardContent, Link } from '@material-ui/core';
import Pending from '@material-ui/icons/HourglassEmpty';
import { useSelector } from 'react-redux';
import { CombinedState } from '../../../reducers/interfaces';
import { ResourceReferenceField } from '../ResourceReferenceField';
import { useUser } from '../../../model/User';

const HasProject = () => {
    const projects = useSelector((state: CombinedState) => state.projects.current).map((project) => project.instance);
    return (
        <FilterList
            label='Project'
            icon={<Pending />}
        >
            {projects.map((project) => (
                <FilterListItem
                    label={project.name}
                    key={project.id}
                    value={{ project: project.id }}
                />
            ))}
        </FilterList>
    );
};

const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block',
            },
            order: 1, // display on the left rather than on the right of the list
            width: '15em',
            marginLeft: '1em',
        }}
    >
        <Card>
            <CardContent>
                <HasProject />
            </CardContent>
        </Card>
    </Box>
);

export const TrainedModelsList: FC<ResourceComponentProps> = (props) => {
    const { user } = useUser();
    if (!user) {
        return null;
    }
    return (
        <List {...props} sort={{ field: 'start_time', order: 'DESC' }} aside={<FilterSidebar />} hasCreate={user.canCreate('trainedmodels')}>
            <>
                <Datagrid>
                    <TextField source='name' sortable={false} />
                    <TextField source='description' sortable={false} />
                    <TextField label='Project' source='project.name' sortable={false} />
                    <ResourceReferenceField source='run' reference='runs' />
                    <ChipField source='framework' />
                </Datagrid>
            </>
        </List>
    );
}
