// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { FC } from 'react';
import {
    Datagrid,
    List,
    ResourceComponentProps,
    TextField,
    FilterList,
    FilterListItem,
    DateFieldProps, FunctionField, ChipField,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import Pending from '@material-ui/icons/HourglassEmpty';
import { useSelector } from 'react-redux';
import { CombinedState } from '../../../reducers/interfaces';
import moment from 'moment';
import { ResourceReferenceField } from '../ResourceReferenceField';

const HasProject = () => {
    const projects = useSelector((state: CombinedState) => state.projects.current).map((project) => project.instance);
    return (
        <FilterList
            label='Project'
            icon={<Pending />}
        >
            {projects.map((project) => (
                <FilterListItem
                    label={project.name}
                    key={project.id}
                    value={{ project_id: project.id }}
                />
            ))}
        </FilterList>
    );
};

const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block',
            },
            order: 1, // display on the left rather than on the right of the list
            width: '15em',
            marginLeft: '1em',
        }}
    >
        <Card>
            <CardContent>
                <HasProject />
            </CardContent>
        </Card>
    </Box>
);

const CustomDateField: FC<DateFieldProps> = (props) => (
    <FunctionField render={(record: any) => moment(record[props.source!]).format('MMMM Do YYYY, HH:mm:ss')} />
);

export const ExperimentList: FC<ResourceComponentProps> = (props) => (
    <List {...props} sort={{ field: 'created_date', order: 'DESC' }} aside={<FilterSidebar />}>
        <>
            <Datagrid rowClick='show'>
                <TextField source='id' sortable={false} label='Id' />
                <ChipField source='project.name' sortable={false} label='Project' />
                <ResourceReferenceField reference='modeltemplates' source='modeltemplate.name' label='Model Template' />
                <TextField source='description' sortable={false} label='Description' />
                <CustomDateField source='created_date' sortable={false} label='Created' />
            </Datagrid>
        </>
    </List>
);
