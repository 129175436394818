import { applyMiddleware, compose, createStore } from 'redux';
import createRootReducer from 'reducers/root-reducer';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { isDev } from 'utils/enviroment';
import thunk from 'redux-thunk';
import { adminSaga, USER_LOGOUT } from 'react-admin';

export default ({
    authProvider,
    dataProvider,
    history,
}) => {
    const reducer = createRootReducer(history);
    const resettableAppReducer = (state, action) => reducer(action.type !== USER_LOGOUT ? state : undefined, action);

    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
                // add your own sagas here
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();
    const logger = createLogger({
        predicate: isDev,
        collapsed: true,
    });

    let appliedMiddlewares = applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        thunk,
        logger,
    );

    if (isDev()) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
        const { composeWithDevTools } = require('redux-devtools-extension');
        appliedMiddlewares = composeWithDevTools(appliedMiddlewares);
    }

    const store = createStore(
        resettableAppReducer,
        { /* set your initial state here */ },
        appliedMiddlewares,
    );
    sagaMiddleware.run(saga);
    return store;
};
