// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, {
    FC, useEffect, useState,
} from 'react';
import {
    Create,
    SelectInput,
    useGetOne,
    useGetList,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    FormDataConsumer,
    BooleanInput,
    required,
    NumberInput,
    TabbedForm,
    FormTab,
} from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiBaseUrl } from '../../../data/customDataProvider';

const validateExperimentCreate = (values) => {
    const errors = {};
    if (!values.parameters) {
        return errors;
    }
    const anyParamOptimizable = values.parameters[0] !== undefined ? values.parameters.filter((v) => v !== undefined).reduce((a, v) => a || (v.optimize || false), false) : false;
    if (!values.algorithm && anyParamOptimizable) {
        errors.algorithm = 'Specifying an algorithm is required if any parameter can be optimized';
    }

    if (!values.metric && anyParamOptimizable) {
        errors.metric = 'Selecting a metric is required if any parameter can be optimized';
    }
    return errors;
};

const TemplateParameterField = ({ source, parameters }) => {
    const lookup = parameters.reduce((a, v) => ({ ...a, [v.name]: v.type }), {});
    return (
        <ArrayInput source={source}>
            <SimpleFormIterator>
                <SelectInput source='name' choices={parameters} optionText='name' optionValue='name' label='Name' validate={required()} />
                <BooleanInput source='optimize' label='Optimize' defaultValue={false} />
                <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                        if (!scopedFormData) {
                            return null;
                        }
                        if (scopedFormData.optimize) {
                            if (lookup[scopedFormData.name] !== 'string') {
                                return (
                                    <>
                                        <TextInput source={getSource('minimum')} label='Minimum' validate={required()} />
                                        <TextInput source={getSource('maximum')} label='Maximum' validate={required()} />
                                    </>
                                );
                            }
                            return (
                                <>
                                    <ArrayInput source={getSource('choices')}>
                                        <SimpleFormIterator>
                                            <TextInput source='value' label='Value' validate={required()} />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </>
                            );
                        }
                        return (
                            <>
                                <TextInput source={getSource('value')} label='Value' />
                            </>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>

    );
};

const TemplateMetricField = ({ source, metrics }) => {
    const choices = metrics.map((p) => ({ id: p.name }));
    return (
        <SelectInput source={source} choices={choices} optionText='id' optionValue='id' />
    );
};

const getParameters = (template) => {
    const record = useGetOne('modeltemplates', template);
    return record.data ? record.data.parameters : [];
};

const getMetrics = (template) => {
    const record = useGetOne('modeltemplates', template);
    return record.data ? record.data.metrics : [];
};

const getTemplates = () => {
    const records = useGetList('modeltemplates');
    const { data } = records;
    return data ? Object.keys(data).map((k) => data[k]) : [];
};

export const ExperimentCreate = (props: any) => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const [projects, setProjects] = useState([]);

    const algorithms = [
        { id: 'bayesianoptimization', value: 'Bayesian Optimization' },
        { id: 'tpe', value: 'Tree of Parzen Estimators' },
        { id: 'multivariate-tpe', value: 'Multivariate Tree of Parzen Estimators' },
        { id: 'hyperband', value: 'Hyperband' },
        { id: 'cmaes', value: 'Covariance Matrix Adaptation Evolution Strategy (CMA-ES)' },
        { id: 'sobol', value: 'Sobol’s Quasirandom Sequence' },
    ];

    useEffect(() => {
        (async () => {
            if (projects.length === 0) {
                const token = await getAccessTokenSilently();
                // eslint-disable-next-line react/destructuring-assignment
                const response = await fetch(`${getApiBaseUrl()}/projects`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const json = await response.json();
                setProjects(json.results);
            }
        })();
    }, []);
    return (
        <Create {...props}>
            <TabbedForm redirect='list' validate={validateExperimentCreate}>
                <FormTab label='Setup'>
                    <TextInput source='description' multiline='true' validate={required()} />
                    <SelectInput source='project' choices={projects} optionText='name' optionValue='id' validate={required()} />
                    <SelectInput source='modeltemplate' choices={getTemplates()} optionText='name' optionValue='id' validate={required()} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <TemplateParameterField
                                source='parameters'
                                parameters={
                                    formData.modeltemplate ?
                                        getParameters(formData.modeltemplate) :
                                        []
                                }
                                {...rest}
                            />
                        )}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label='optimize'>
                    <SelectInput source='algorithm' choices={algorithms} optionText='value' optionValue='id' />
                    <NumberInput source='max_trials' min={5} max={100} defaultValue={10} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <TemplateMetricField
                                source='metric'
                                metrics={
                                    formData.modeltemplate ?
                                        getMetrics(formData.modeltemplate) :
                                        []
                                }
                                {...rest}
                            />
                        )}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};
