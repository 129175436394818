import { ChipField, useRecordContext, useRedirect } from 'react-admin';
import React from 'react';
import { get } from "lodash";

export const ResourceReferenceField = (props) => {
    const record = useRecordContext(props);
    if (record) {
        const { source, reference, ...rest } = props;
        const value = get(record, source);
        console.log(value);
        const path = `/${reference}/${value}/show`;
        console.log(path);
        const redirect = useRedirect();
        return value !== null ?
            <ChipField source={source} sortable={false} clickable onClick={(e) => { e.stopPropagation(); redirect(path); }} {...rest}  /> : null;
    }
    return null;
};
