import React, { FC } from 'react';
import { ChipField, TextFieldProps } from 'react-admin';
import Published from '@material-ui/icons/SpellcheckOutlined';
import Pending from '@material-ui/icons/HourglassEmpty';
import Cancel from '@material-ui/icons/CloseOutlined';

const RunStateField: FC<TextFieldProps> = (props) => {
    switch (props.record!.status) {
        case 'FINISHED': return <ChipField icon={<Published />} source='id' color='secondary' />;
        case 'RUNNING': return <ChipField icon={<Pending />} source='id' color='default' />;
        case 'FAILED': return <ChipField icon={<Cancel />} source='id' color='default' />;
        default: return <ChipField source='id' color='default' />;
    }
};
export default RunStateField;
