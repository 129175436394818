// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import * as React from 'react';
import { createHashHistory } from 'history';
import { Admin, Resource } from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import DirectionsRun from '@material-ui/icons/DirectionsRunOutlined';
import RegisteredModel from '@material-ui/icons/FlagOutlined';
import ModelTemplate from '@material-ui/icons/FunctionsOutlined';
import Report from '@material-ui/icons/InsertDriveFile';
import Trial from '@material-ui/icons/ExploreOutlined';
import Home from '@material-ui/icons/HomeOutlined';
import Task from '@material-ui/icons/ScheduleOutlined';
import Settings from '@material-ui/icons/BuildOutlined';
import Project from '@material-ui/icons/AccountTreeOutlined';

import createAdminStore from './createAdminStore';
import customDataProvider from './data/customDataProvider';
import customAuthProvider from './authProvider';
import { theme } from './themes/theme';
import customRoutes from './customRoutes';
import CustomLayout from './components/layout/CustomLayout';
import CVATApplication from './components/cvat-app';
import { setCVATStore } from './cvat-store';
import TreeMenu from './components/ra/treemenu/TreeMenu';

import { RunsList } from './components/ra/run/RunsList';
import { ExperimentCreate } from './components/ra/experiment/ExperimentCreate';
import { ExperimentList } from './components/ra/experiment/ExperimentList';
import { ExperimentShow } from './components/ra/experiment/ExperimentShow';
import { RunShow } from './components/ra/run/RunsShow';
import { TrainedModelsList } from './components/ra/model/TrainedModelsList';
import { TrainedModelsCreate } from './components/ra/model/TrainedModelsCreate';
import { ModelTemplatesList } from './components/ra/model/ModelTemplatesList';
import { ModelTemplatesCreate } from './components/ra/model/ModelTemplatesCreate';
import { ReportsList } from './components/ra/report/ReportsList';
import { ReportsCreate } from './components/ra/report/ReportsCreate';
import { OverviewList } from './components/ra/overview/OverviewList';
import { ProjectList } from './components/ra/project/ProjectList';

import 'antd/dist/antd.css';
import './styles.scss';

const App = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    React.useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Oops...
                {error.message}
            </div>
        );
    }

    if (isAuthenticated) {
        const authProvider: customAuthProvider = customAuthProvider({
            getAccessTokenSilently,
            isAuthenticated,
            isLoading,
            loginWithRedirect,
            logout,
            user,
        });

        const dataProvider = customDataProvider(getAccessTokenSilently, () => '/api');
        const history = createHashHistory();
        const store = createAdminStore({
            authProvider,
            dataProvider,
            history,
        });

        const extraLinks = [
            //{ icon: Project, options: { label: 'Projects', menuParent: 'cv' }, name: 'projects' },
            { icon: Task, options: { label: 'Labelling', menuParent: 'cv' }, name: 'tasks' },
            { icon: Settings, options: { label: 'Settings', menuParent: 'cv', force: true}, name: 'settings' },
        ];

        setCVATStore(store);
        window.store = store;

        return (
            <Provider store={store}>
                <Admin
                    menu={(props) => TreeMenu({ extra: extraLinks, ...props })}
                    authProvider={authProvider}
                    dataProvider={dataProvider}
                    history={history}
                    title='My Admin'
                    customRoutes={customRoutes}
                    theme={theme}
                    layout={CustomLayout}
                >
                    <Resource name='ra-projects' icon={Project} list={ProjectList} options={{ label: 'Projects', force: true }} />
                    <Resource name='models' options={{ label: 'Models', isMenuParent: true }} />
                    <Resource icon={ModelTemplate} name='modeltemplates' list={ModelTemplatesList} create={ModelTemplatesCreate} options={{ label: 'Model Templates', menuParent: 'models' }} />
                    <Resource icon={RegisteredModel} name='trainedmodels' list={TrainedModelsList} create={TrainedModelsCreate} options={{ label: 'Registered Models', menuParent: 'models' }} />
                    <Resource icon={Trial} name='experiments' list={ExperimentList} create={ExperimentCreate} show={ExperimentShow} options={{ label: 'Experiments', menuParent: 'models' }} />
                    <Resource icon={DirectionsRun} name='runs' list={RunsList} show={RunShow} options={{ label: 'Runs', menuParent: 'models' }} />
                    <Resource name='cv' options={{ label: 'Computer Vision', isMenuParent: true }} />
                    <Resource icon={Report} name='reports' list={ReportsList} create={ReportsCreate} options={{ label: 'Reports', menuParent: 'cv' }} />
                    <Resource name='nlp' options={{ label: 'NLP', isMenuParent: true }} />
                </Admin>
                <CVATApplication getToken={getAccessTokenSilently} />
            </Provider>
        );
    }
    return <div />;
};

export default App;
