// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { FC } from 'react';
import {
    ChipField, Datagrid, List, ResourceComponentProps, TextField, TextFieldProps, FilterList, FilterListItem, ArrayField, SingleFieldList
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import Pending from '@material-ui/icons/HourglassEmpty';
import Published from '@material-ui/icons/SpellcheckOutlined';
import Cancel from '@material-ui/icons/CloseOutlined';
import UnixTimestampDateField from '../UnixTimestampDateField';
import RunStateField from '../RunStateField';
import { ResourceReferenceField } from '../ResourceReferenceField';

import { useSelector } from 'react-redux';
import { CombinedState } from '../../../reducers/interfaces';

const HasStatus = () => (
    <FilterList
        label='Status'
        icon={<Pending />}
    >
        <FilterListItem
            label='Running'
            value={{ 'attributes.status': 'RUNNING' }}
        />
        <FilterListItem
            label='Finished'
            value={{ 'attributes.status': 'FINISHED' }}
        />
        <FilterListItem
            label='Failed'
            value={{ 'attributes.status': 'FAILED' }}
        />
    </FilterList>
);

const HasProject = () => {
    const projects = useSelector((state: CombinedState) => state.projects.current).map((project) => project.instance);
    return (
        <FilterList
            label='Project'
            icon={<Pending />}
        >
            {projects.map((project) => (
                <FilterListItem
                    label={project.name}
                    key={project.id}
                    value={{ experiment_id: [project.mlflow_id] }}
                />
            ))}
        </FilterList>
    );
};

const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block',
            },
            order: 1, // display on the left rather than on the right of the list
            width: '15em',
            marginLeft: '1em',
        }}
    >
        <Card>
            <CardContent>
                <HasStatus />
                <HasProject />
            </CardContent>
        </Card>
    </Box>
);

export const RunsList: FC<ResourceComponentProps> = (props) => (
    <List {...props} sort={{ field: 'start_time', order: 'DESC' }} aside={<FilterSidebar />}>
        <>
            <Datagrid rowClick='show'>
                <RunStateField source='status' sortable={false} label='Id' />
                <ChipField source='project.name' sortable={false} label='project' />
                <ResourceReferenceField source='experiment' reference='experiments' sortable={false} />
                <TextField source='type' sortable={false} />
                <ArrayField source='labels' sortable={false}>
                    <SingleFieldList>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source='metrics' sortable={false}>
                    <SingleFieldList>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ArrayField>
                <UnixTimestampDateField source='start_time' sortable={false} />
            </Datagrid>
        </>
    </List>
);
